<template>
  <div class="join-us">
    <div class="top-banner">
      <img
        class="pc"
        :src="require('../assets/image/join_us_banner.webp')"
        alt=""
        @load="imgLoad"
      />
      <img
        class="h5"
        :src="require('../assets/image/iphone/join_us_banner.png')"
        alt=""
      />
      <div class="base-container">
        <div class="banner-title">
          孚厘科技 <span>2023-2024</span> 校园小微金融人才培养项目
        </div>
      </div>
    </div>
    <div class="nav-bar-wrapper">
      <div class="nav-bar" id="nav-bar-id">
        <div class="base-container">
          <div class="nav-bar-list-wrapper">
            <div
              :class="`nav-bar-list ${
                activeKey === item.key ? 'nav-bar-list-active' : ''
              }`"
              v-for="item in navBarList"
              :key="item.key"
              @click="navBarClick(item.key)"
            >
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="base-container join-us-container">
      <div class="join-wrapper" id="join">
        <div class="base-title">加入孚厘</div>
        <div class="base-title-desc">做好职业生涯的第一次 [ 风险控制 ]</div>
        <div class="join-list-wrapper">
          <div class="join-list" v-for="item in joinList" :key="item.title">
            <div class="join-list-top">
              <img :src="item.imgUrl" alt="" />
            </div>
            <div class="join-list-bottom">
              <div class="join-list-bottom-title">{{ item.title }}</div>
              <div class="join-list-bottom-desc">{{ item.desc }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="position-wrapper" id="position">
      <div class="base-container">
        <div class="base-title">开放职位</div>
        <div class="position-title-desc">应届生、在校生均可投递</div>
        <div class="position-list-wrapper">
          <div class="position-list">
            <div class="list-icon">
              <img
                :src="require('../assets/image/position_list1.png')"
                alt=""
              />
            </div>
            <div class="list-bottom">
              <div class="list-title">营销储备生</div>
              <div class="list-detail pc" @click="linkChuBei">
                了解职位详情 <i class="el-icon-arrow-right"></i>
              </div>
              <div class="list-detail h5" @click="linkChuBeiH5">
                了解职位详情 <i class="el-icon-arrow-right"></i>
              </div>
            </div>
          </div>
          <div class="position-list">
            <div class="list-icon">
              <img
                :src="require('../assets/image/position_list2.png')"
                alt=""
              />
            </div>
            <div class="list-bottom">
              <div class="list-title">金融管培生</div>
              <div class="list-detail pc" @click="linkGuanPei">
                了解职位详情 <i class="el-icon-arrow-right"></i>
              </div>
              <div class="list-detail h5" @click="linkGuanPeiH5">
                了解职位详情 <i class="el-icon-arrow-right"></i>
              </div>
            </div>
          </div>
        </div>
        l
      </div>
    </div>
    <div class="base-container join-us-container">
      <div class="recruit-wrapper pc" id="recruit">
        <div class="base-title">招聘流程</div>
        <div class="recruit-list-wrapper">
          <div class="recruit-list recruit-list1">
            <div class="recruit-list-title">网申</div>
          </div>
          <div class="recruit-list recruit-list2">
            <div class="recruit-list-title">简历初筛</div>
          </div>
          <div class="recruit-list recruit-list3">
            <div class="recruit-list-title">HR面试</div>
          </div>
          <div class="recruit-list recruit-list4">
            <div class="recruit-list-title">业务面试</div>
          </div>
          <div class="recruit-list recruit-list5">
            <div class="recruit-list-title">发放OFFER</div>
          </div>
          <div class="recruit-list recruit-list6">
            <div class="recruit-list-title">第三方签约</div>
          </div>
          <div class="recruit-list recruit-list7">
            <div class="recruit-list-title">入职（可提前实习）</div>
          </div>
        </div>
      </div>
      <div class="recruit-wrapper h5">
        <div class="base-title">招聘流程</div>
        <div class="recruit-list-wrapper">
          <div class="top-list-wrapper">
            <div class="recruit-list recruit-list1">
              <div class="recruit-list-title">网申</div>
            </div>
            <div class="recruit-list recruit-list2">
              <div class="recruit-list-title">简历初筛</div>
            </div>
            <div class="recruit-list recruit-list3">
              <div class="recruit-list-title">HR面试</div>
            </div>
            <div class="recruit-list recruit-list4">
              <div class="recruit-list-title">业务面试</div>
            </div>
          </div>
          <div class="bottom-list-wrapper">
            <div class="recruit-list recruit-list5">
              <div class="recruit-list-title">发放OFFER</div>
            </div>
            <div class="recruit-list recruit-list6">
              <div class="recruit-list-title">第三方签约</div>
            </div>
            <div class="recruit-list recruit-list7">
              <div class="recruit-list-title">入职（可提前实习）</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="development-wrapper" id="development">
      <div class="base-title">成长在孚厘</div>
      <div class="base-container">
        <div class="new-development-box">
          <div class="development-list-wrapper">
            <div class="development-list">
              <div class="development-list-box">
                <div class="development-list-content">
                  <div class="development-list-content-title">
                    <div>2016届校招生-Cindy</div>
                    <div>现担任业务运营室主任</div>
                  </div>
                  <div class="development-list-content-desc">
                    一线工作让我了解产品、理解业务、懂得客户，这是运营支持最宝贵的经验。
                  </div>
                </div>
              </div>
            </div>
            <div class="development-list">
              <div class="development-list-box development-list-box-reverse">
                <div class="development-list-content">
                  <div class="development-list-content-title">
                    <div>2017届校招生-Vivian</div>
                    <div>现担任区域经理</div>
                  </div>
                  <div class="development-list-content-desc">
                    成长的秘诀就是-站在客户的角度思考问题，帮助客户也是成全自己。
                  </div>
                </div>
              </div>
            </div>
            <div class="development-list">
              <div class="development-list-box">
                <div class="development-list-content">
                  <div class="development-list-content-title">
                    <div>2018届校招生-Carl</div>
                    <div>现担任区域经理</div>
                  </div>
                  <div class="development-list-content-desc">
                    为想要的生活坚持，没有担忧被拒绝的闲暇。
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="box-right"></div>
        </div>
      </div>
    </div>
    <div class="base-container join-us-container">
      <div class="work-wrapper" id="work">
        <div class="base-title">工作在孚厘</div>
        <div class="work-list-wrapper">
          <div class="work-list-left">
            <div class="left-content">
              <div class="left-content-title">公司总部坐落于黄浦江畔</div>
              <div class="left-content-desc">
                <div>徐汇滨江核心板块</div>
                <div>抬眼即享一线江景</div>
              </div>
            </div>
          </div>
          <div class="work-list-right">
            <div class="work-list-right-top">
              <div class="right-box-content">
                我们注重工作环境的设计和改善，为员工提供舒适、开放、富有创意的工作空间
              </div>
            </div>
            <div class="work-list-right-bottom">
              <div class="bottom-list1"></div>
              <div class="bottom-list2"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="life-wrapper" id="life">
        <div class="base-title">孚厘生活</div>
        <div class="life-list-wrapper">
          <div class="life-top">
            <div class="box-content">
              <div class="content-title">年度团建，征途是星辰大海</div>
              <div class="content-desc">
                <div>生活有诗，工作也有远方</div>
                <div>总有一刻你会想要逃离城市的喧嚣</div>
              </div>
            </div>
          </div>
          <div class="life-bottom">
            <div class="life-bottom-list life-bottom-list1">
              <div class="box-content">
                <div class="content-title">工作闲暇，来一杯下午茶</div>
                <div class="content-desc">
                  <div>零食、下午茶无限供应， 繁忙之余也需要舒适的体验。</div>
                </div>
              </div>
            </div>
            <div class="life-bottom-list life-bottom-list2">
              <div class="box-content">
                <div class="content-title">总决赛MVP就是你</div>
                <div class="content-desc">
                  <div>孚厘杯篮球赛已经连续举办6届 新赛季期待你的加入</div>
                </div>
              </div>
            </div>
            <div class="life-bottom-list life-bottom-list3">
              <div class="box-content">
                <div class="content-title">女神节与花</div>
                <div class="content-desc">
                  <div>
                    每个特别的日子，孚厘都和你一起度过节日礼物，生日Party，更多惊喜等你解锁
                  </div>
                </div>
              </div>
            </div>
            <div class="life-bottom-list life-bottom-list4">
              <div class="box-content">
                <div class="content-title">星夜篝火，开启你的孚厘成长之路</div>
                <div class="content-desc">
                  <div>
                    认识新朋友，掌握新知识，和大咖面对面踏出从学生到职场人的华丽第一步
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "JoinUs",
  data() {
    return {
      activeKey: "join",
      navBarList: [
        {
          title: "加入孚厘",
          key: "join",
        },
        {
          title: "开放职位",
          key: "position",
        },
        {
          title: "招聘流程",
          key: "recruit",
        },
        {
          title: "成长在孚厘",
          key: "development",
        },
        {
          title: "工作在孚厘",
          key: "work",
        },
        {
          title: "孚厘生活",
          key: "life",
        },
      ],
      navBarHeight: 0,
      navBarScrollTop: 0,
      joinList: [
        {
          title: "上不封顶的薪资待遇",
          desc: "公司设立新人保护，年终奖、贡献奖及定期调薪等激励计划，让员工收益和公司发展紧密相连。",
          imgUrl: require("../assets/image/join_list1.png"),
        },
        {
          title: "保姆级别的应届生培养",
          desc: "公司提供新人集训、进阶提升等线上/线下培训，还有1对1导师保驾护航，助力新人成长",
          imgUrl: require("../assets/image/join_list2.png"),
        },
        {
          title: "多样化的晋升发展路径",
          desc: "公司有完整的晋升通道和的轮岗竞聘机制。在孚厘，每个人都有足够的发展空间。",
          imgUrl: require("../assets/image/join_list3.png"),
        },
        {
          title: "面面俱到的福利补贴",
          desc: "五险一金、带薪年假、弹性工作、周末双休、节日津贴、高温补贴，全薪病假、年度体检。",
          imgUrl: require("../assets/image/join_list4.png"),
        },
        {
          title: "孚厘员工的专属 [特权]",
          desc: "上海落户资质、无息购车福利、高额交通补贴，父母慈恩基金。",
          imgUrl: require("../assets/image/join_list5.png"),
        },
        {
          title: "丰富多彩的 [课余] 生活",
          desc: "KTV麦霸、游戏达人、灌篮高手，孚厘都有你的舞台！还有年度旅游、员工生日会等你来玩。",
          imgUrl: require("../assets/image/join_list6.png"),
        },
      ],
      boxListTop: [],
    };
  },
  mounted() {
    // 获取小的navbar的高度
    this.navBarHeight = document.getElementById("nav-bar-id").offsetHeight;
    // 获取小的navbar具体顶部的距离
    this.navBarScrollTop =
      document.getElementById("nav-bar-id").getBoundingClientRect().top +
      this.getScrollTop();
    // 获取当前锚点所有的dom
    const boxListDom = this.navBarList.map((item) =>
      document.getElementById(item.key)
    );
    // 获取当前锚点所有的scrollTop
    this.boxListTop = boxListDom.map((item) =>
      Math.floor(
        item.getBoundingClientRect().top +
          this.getScrollTop() -
          this.navBarHeight
      )
    );
    window.addEventListener("scroll", this.scrollChange);
  },
  methods: {
    imgLoad() {
      // 获取小的navbar具体顶部的距离
      this.navBarScrollTop =
        document.getElementById("nav-bar-id").getBoundingClientRect().top +
        this.getScrollTop();
    },
    linkChuBei() {
      let url =
        "https://xiaoyuan.zhaopin.com/job/CC314057130J40458858909?jobSourceType=2&jobfrom=practicesearch&productId=-1&channelId=-1";
      window.open(url, "_bank");
    },
    linkGuanPei() {
      let url =
        "https://xiaoyuan.zhaopin.com/job/CC314057130J40459024709?jobSourceType=2&jobfrom=practicesearch&productId=-1&channelId=-1";
      window.open(url, "_bank");
    },
    linkChuBeiH5() {
      let url =
        "https://m.zhaopin.com/xiaoyuan/position/detail?redirect=mxiaoyuan&id=CC314057130J40458858909&openmp=0&utmsource=searchlis";
      window.open(url, "_bank");
    },
    linkGuanPeiH5() {
      let url =
        "https://m.zhaopin.com/xiaoyuan/position/detail?redirect=mxiaoyuan&id=CC314057130J40459024709&openmp=0&utmsource=searchlist";
      window.open(url, "_bank");
    },
    scrollChange() {
      let scrollTop = this.getScrollTop();
      // 根据滚动高度大导航栏消失
      if (scrollTop > 80) {
        let topNavBarClass = document.getElementById("top-nav-bar").className;
        if (topNavBarClass.indexOf("hidden-nav-bar") === -1) {
          document.getElementById("top-nav-bar").className += " hidden-nav-bar";
        }
      } else {
        document.getElementById("top-nav-bar").className = "nav-bar";
      }
      // 根据滚动高度小导航栏绝对定位
      if (scrollTop > this.navBarScrollTop) {
        const currentNavBarClass =
          document.getElementById("nav-bar-id").className;
        if (currentNavBarClass.indexOf("fixed-nav-bar") === -1) {
          document.getElementById("nav-bar-id").className += " fixed-nav-bar";
        }
      } else {
        document.getElementById("nav-bar-id").className = "nav-bar";
      }
      if (scrollTop >= this.boxListTop[this.boxListTop.length - 1] - 100) {
        this.activeKey = this.navBarList[this.boxListTop.length - 1].key;
      } else if (scrollTop >= 0 && scrollTop < this.boxListTop[1]) {
        this.activeKey = this.navBarList[0].key;
      } else {
        this.boxListTop.forEach((item, index) => {
          if (scrollTop >= item && scrollTop < this.boxListTop[index + 1]) {
            this.activeKey = this.navBarList[index].key;
          }
        });
      }
    },
    getScrollTop() {
      let scrollTop =
        document.documentElement.scrollTop ||
        window.pageYOffset ||
        document.body.scrollTop;
      return scrollTop;
    },
    navBarClick(key) {
      this.activeKey = key;
      let scrollTop =
        this.getScrollTop() +
        document.getElementById(key).getBoundingClientRect().top;
      document.documentElement.scrollTop = scrollTop - this.navBarHeight;
      document.body.scrollTop = scrollTop - this.navBarHeight;
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollChange);
  },
};
</script>

<style scoped lang="scss">
.join-us {
  .top-banner {
    width: 100%;
    position: relative;
    font-size: 0px;
    img {
      width: 100%;
      height: auto;
    }
    .base-container {
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 100%;
    }
    .banner-title {
      font-size: 32px;
      font-weight: bold;
      color: #fffcfc;
      text-align: center;
      line-height: 48px;
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      > span {
        color: #01e5ff;
      }
    }
  }
  .base-title {
    font-size: 36px;
    font-weight: bold;
    color: #333333;
    line-height: 50px;
    text-align: center;
    margin-top: 80px;
    margin-bottom: 48px;
  }
  .join-us-container {
    .join-wrapper {
      .base-title {
        margin-bottom: 10px;
      }
      .base-title-desc {
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        line-height: 26px;
        margin-bottom: 28px;
        text-align: center;
      }
      .join-list-wrapper {
        display: flex;
        flex-wrap: wrap;
        .join-list {
          width: 400px;
          padding: 30px 30px;
          box-sizing: border-box;
          .join-list-top {
            display: flex;
            justify-content: center;
            margin-bottom: 22px;
            img {
              width: 48px;
              height: 48px;
            }
          }
          .join-list-bottom {
            .join-list-bottom-title {
              font-size: 18px;
              font-weight: bold;
              text-align: center;
              color: #333333;
              line-height: 24px;
              margin-bottom: 6px;
            }
            .join-list-bottom-desc {
              font-size: 14px;
              font-weight: 400;
              color: #666666;
              line-height: 24px;
              text-align: center;
            }
          }
        }
      }
    }
    .recruit-wrapper {
      .recruit-list-wrapper {
        display: flex;
        .recruit-list {
          background-size: 100% auto;
          position: relative;
          width: 168.55px;
          height: 211px;
          &:nth-child(odd) {
            background-position: 0 -1.5px;
          }
          .recruit-list-title {
            font-size: 18px;
            font-weight: bold;
            color: #333333;
            line-height: 26px;
            text-align: center;
            position: absolute;
            bottom: 55px;
            left: 0px;
            width: 100%;
          }
        }
        .recruit-list1 {
          background-image: url("../assets/image/recruit-list1.png");
          background-repeat: no-repeat;
        }
        .recruit-list2 {
          background-image: url("../assets/image/recruit-list2.png");
          background-repeat: no-repeat;
        }
        .recruit-list3 {
          background-image: url("../assets/image/recruit-list3.png");
          background-repeat: no-repeat;
        }
        .recruit-list4 {
          background-image: url("../assets/image/recruit-list4.png");
          background-repeat: no-repeat;
        }
        .recruit-list5 {
          background-image: url("../assets/image/recruit-list5.png");
          background-repeat: no-repeat;
        }
        .recruit-list6 {
          background-image: url("../assets/image/recruit-list6.png");
          background-repeat: no-repeat;
        }
        .recruit-list7 {
          background-image: url("../assets/image/recruit-list7.png");
          background-repeat: no-repeat;
        }
      }
    }
    .work-wrapper {
      .work-list-wrapper {
        display: flex;
        justify-content: space-between;
        .work-list-left {
          width: 595px;
          height: 664px;
          border-radius: 10px;
          background-size: 100%;
          background-image: url("../assets/image/overview_right_bg.png");
          position: relative;
          overflow: hidden;
          .left-content {
            width: 100%;
            height: 96px;
            background: linear-gradient(
              90deg,
              rgba(0, 0, 0, 0.52) 0%,
              rgba(255, 255, 255, 0) 100%
            );
            position: absolute;
            bottom: 0px;
            left: 0px;
            padding: 10px 20px;
            box-sizing: border-box;
            .left-content-title {
              font-size: 18px;
              font-weight: bold;
              color: #ffffff;
              line-height: 24px;
              margin-bottom: 10px;
            }
            .left-content-desc {
              font-size: 14px;
              color: #ffffff;
              line-height: 20px;
            }
          }
        }
        .work-list-right {
          width: 595px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .work-list-right-top {
            width: 595px;
            height: 327px;
            border-radius: 10px;
            background-size: 100%;
            background-image: url("../assets/image/work_list1.png");
            position: relative;
            overflow: hidden;
          }
          .work-list-right-bottom {
            display: flex;
            justify-content: space-between;
            .bottom-list1,
            .bottom-list2 {
              background-size: 100%;
              width: 293px;
              height: 327px;
              border-radius: 10px;
            }
            .bottom-list1 {
              background-image: url("../assets/image/work_list2.png");
            }
            .bottom-list2 {
              background-image: url("../assets/image/work_list3.png");
            }
          }
          .right-box-content {
            background: linear-gradient(
              90deg,
              rgba(0, 0, 0, 0.52) 0%,
              rgba(255, 255, 255, 0) 100%
            );
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
            line-height: 20px;
            padding: 11px 20px;
            box-sizing: border-box;
            position: absolute;
            bottom: 0px;
            left: 0px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
          }
        }
      }
    }
    .life-wrapper {
      margin-bottom: 80px;
      .life-list-wrapper {
        display: flex;
        flex-direction: column;
        .life-top {
          width: 1200px;
          height: 412px;
          background-image: url("../assets/image/life_top_bg.png");
          background-size: 100%;
          overflow: hidden;
          border-radius: 10px;
          margin-bottom: 10px;
          position: relative;
        }
        .life-bottom {
          display: flex;
          justify-content: space-between;
          .life-bottom-list {
            width: 293px;
            height: 327px;
            border-radius: 10px;
            background-size: 100%;
            position: relative;
            overflow: hidden;
          }
          .life-bottom-list1 {
            background-image: url("../assets/image/life_bottom_list1.png");
          }
          .life-bottom-list2 {
            background-image: url("../assets/image/life_bottom_list2.png");
          }
          .life-bottom-list3 {
            background-image: url("../assets/image/life_bottom_list3.png");
          }
          .life-bottom-list4 {
            background-image: url("../assets/image/life_bottom_list4.png");
          }
        }
        .box-content {
          width: 100%;
          padding: 20px 11px;
          background: linear-gradient(
            90deg,
            rgba(0, 0, 0, 0.52) 0%,
            rgba(0, 0, 0, 0) 100%
          );
          position: absolute;
          left: 0px;
          bottom: 0px;
          box-sizing: border-box;
          .content-title {
            font-size: 18px;
            font-weight: bold;
            color: #ffffff;
            line-height: 24px;
            margin-bottom: 10px;
          }
          .content-desc {
            font-size: 14px;
            color: #ffffff;
            line-height: 20px;
          }
        }
      }
    }
  }
  .position-wrapper {
    width: 100%;
    height: 606px;
    background-image: url("../assets/image/join_position_bg.png");
    background-size: 100%;
    margin-top: 60px;
    overflow: hidden;
    .base-title {
      color: #ffffff;
      margin-bottom: 10px;
      margin-top: 72px;
    }
    .position-title-desc {
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
      line-height: 26px;
      text-align: center;
    }
    .position-list-wrapper {
      margin-top: 48px;
      display: flex;
      justify-content: space-between;
      .position-list {
        width: 567px;
        height: 330px;
        background-color: rgba(2, 124, 250, 0.2);
        padding: 68px 0px;
        box-sizing: border-box;
        .list-icon {
          display: flex;
          justify-content: center;
          img {
            width: 65px;
            height: 65px;
          }
        }
        .list-bottom {
          margin-top: 15px;
          text-align: center;
          .list-title {
            font-size: 32px;
            font-weight: bold;
            color: #ffffff;
            line-height: 48px;
            margin-bottom: 20px;
          }
          .list-detail {
            font-size: 18px;
            color: #ffffff;
            line-height: 28px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .development-wrapper {
    .new-development-box {
      display: flex;
      border-radius: 10px;
      overflow: hidden;
      background: #f7f8fa;
      .box-right {
        width: 544px;
        background-size: 100% 100%;
        background-image: url("../assets/image/development_right_bg.png");
      }
    }
    .development-list-wrapper {
      flex: 1;
      height: 815px;
      .development-list {
        .development-list-content {
          width: 100%;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          flex-direction: column;
          padding: 87px 55px 0px;
          .development-list-content-title {
            width: 200px;
            font-size: 20px;
            font-weight: bold;
            color: #333333;
            line-height: 32px;
            padding-bottom: 10px;
            position: relative;
            &:before {
              content: "";
              display: block;
              position: absolute;
              bottom: 0px;
              height: 1px;
              width: 100%;
              background: linear-gradient(
                to right,
                #4782f7 0%,
                #4782f7 20%,
                #e4e7eb 20%,
                #e4e7eb 100%
              );
            }
          }
          .development-list-content-desc {
            font-size: 16px;
            font-weight: 400;
            color: #666666;
            line-height: 26px;
            margin-top: 27px;
          }
        }
      }
    }
  }
  .h5 {
    display: none;
  }
}
@media screen and (max-width: 992px) {
  .join-us {
    .top-banner {
      width: 100%;
      position: relative;
      img {
        width: 100%;
        height: auto;
      }
      .base-container {
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 100%;
      }
      .banner-title {
        font-size: 72px;
        line-height: 84px;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        > span {
          color: #01e5ff;
        }
      }
    }
    .base-title {
      font-size: 82px;
      line-height: 123px;
      text-align: center;
      margin-top: 154px;
      margin-bottom: 105px;
    }
    .join-us-container {
      .join-wrapper {
        .base-title {
          margin-bottom: 64px;
        }
        .base-title-desc {
          font-size: 56px;
          line-height: 66px;
          margin-bottom: 100px;
          text-align: center;
        }
        .join-list-wrapper {
          display: flex;
          flex-wrap: wrap;
          .join-list {
            width: 50%;
            padding: 80px 102px;
            box-sizing: border-box;
            .join-list-top {
              display: flex;
              justify-content: center;
              margin-bottom: 56px;
              img {
                width: 123px;
                height: 123px;
              }
            }
            .join-list-bottom {
              .join-list-bottom-title {
                font-size: 51px;
                line-height: 67px;
                margin-bottom: 15px;
              }
              .join-list-bottom-desc {
                font-size: 36px;
                line-height: 61px;
                text-align: center;
              }
            }
          }
        }
      }
      .recruit-wrapper {
        .recruit-list-wrapper {
          display: block;
          .top-list-wrapper,
          .bottom-list-wrapper {
            display: flex;
          }
          .recruit-list {
            flex: 1;
            background-size: 100% auto;
            position: relative;
            height: 540px;
            &:nth-child(odd) {
              background-position: 0 -1px;
            }
            .recruit-list-title {
              font-size: 51px;
              line-height: 67px;
              text-align: center;
              position: absolute;
              bottom: 55px;
              left: 0px;
              width: 100%;
            }
          }
          .recruit-list1 {
            background-image: url("../assets/image/recruit-list1.png");
            background-repeat: no-repeat;
          }
          .recruit-list2 {
            background-image: url("../assets/image/recruit-list2.png");
            background-repeat: no-repeat;
          }
          .recruit-list3 {
            background-image: url("../assets/image/recruit-list3.png");
            background-repeat: no-repeat;
          }
          .recruit-list4 {
            background-image: url("../assets/image/recruit-list4.png");
            background-repeat: no-repeat;
          }
          .recruit-list5 {
            background-image: url("../assets/image/recruit-list5.png");
            background-repeat: no-repeat;
          }
          .recruit-list6 {
            background-image: url("../assets/image/recruit-list6.png");
            background-repeat: no-repeat;
          }
          .recruit-list7 {
            background-image: url("../assets/image/recruit-list7.png");
            background-repeat: no-repeat;
          }
        }
      }
      .work-wrapper {
        .work-list-wrapper {
          display: flex;
          justify-content: space-between;
          .work-list-left {
            width: 870px;
            height: 1700px;
            border-radius: 10px;
            background-size: 100% 100%;
            background-image: url("../assets/image/overview_right_bg.png");
            position: relative;
            overflow: hidden;
            .left-content {
              width: 100%;
              height: 246px;
              background: linear-gradient(
                90deg,
                rgba(0, 0, 0, 0.52) 0%,
                rgba(255, 255, 255, 0) 100%
              );
              position: absolute;
              bottom: 0px;
              left: 0px;
              padding: 28px 51px;
              box-sizing: border-box;
              .left-content-title {
                font-size: 46px;
                line-height: 61px;
                margin-bottom: 26px;
              }
              .left-content-desc {
                font-size: 36px;
                line-height: 51px;
              }
            }
          }
          .work-list-right {
            width: 870px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .work-list-right-top {
              width: 870px;
              height: 837px;
              border-radius: 10px;
              background-size: 100% 100%;
              background-image: url("../assets/image/work_list1.png");
              position: relative;
              overflow: hidden;
            }
            .work-list-right-bottom {
              display: flex;
              justify-content: space-between;
              .bottom-list1,
              .bottom-list2 {
                background-size: 100% 100%;
                width: 422px;
                height: 837px;
                border-radius: 10px;
              }
              .bottom-list1 {
                background-image: url("../assets/image/work_list2.png");
              }
              .bottom-list2 {
                background-image: url("../assets/image/work_list3.png");
              }
            }
            .right-box-content {
              background: linear-gradient(
                90deg,
                rgba(0, 0, 0, 0.52) 0%,
                rgba(255, 255, 255, 0) 100%
              );
              font-size: 36px;
              line-height: 51px;
              padding: 28px 51px;
              box-sizing: border-box;
              position: absolute;
              bottom: 0px;
              left: 0px;
            }
          }
        }
      }
      .life-wrapper {
        margin-bottom: 154px;
        .life-list-wrapper {
          display: flex;
          flex-direction: column;
          .life-top {
            width: 100%;
            height: 1055px;
            background-image: url("../assets/image/iphone/life_top_bg.png");
            background-size: 100% 100%;
            overflow: hidden;
            border-radius: 10px;
            margin-bottom: 26px;
            position: relative;
          }
          .life-bottom {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .life-bottom-list {
              width: 870px;
              height: 837px;
              border-radius: 10px;
              background-size: 100% 100%;
              position: relative;
              overflow: hidden;
              &:nth-child(-n + 2) {
                margin-bottom: 26px;
              }
            }
            .life-bottom-list1 {
              background-image: url("../assets/image/life_bottom_list1.png");
            }
            .life-bottom-list2 {
              background-image: url("../assets/image/life_bottom_list2.png");
            }
            .life-bottom-list3 {
              background-image: url("../assets/image/life_bottom_list3.png");
            }
            .life-bottom-list4 {
              background-image: url("../assets/image/life_bottom_list4.png");
            }
          }
          .box-content {
            width: 100%;
            padding: 28px 51px;
            background: linear-gradient(
              90deg,
              rgba(0, 0, 0, 0.52) 0%,
              rgba(0, 0, 0, 0) 100%
            );
            position: absolute;
            left: 0px;
            bottom: 0px;
            box-sizing: border-box;
            .content-title {
              font-size: 46px;
              line-height: 61px;
              margin-bottom: 26px;
            }
            .content-desc {
              font-size: 36px;
              line-height: 51px;
            }
          }
        }
      }
    }
    .position-wrapper {
      width: 100%;
      height: 1585px;
      background-image: url("../assets/image/join_position_bg.png");
      background-size: 100% 100%;
      margin-top: 60px;
      overflow: hidden;
      .base-title {
        color: #ffffff;
        margin-bottom: 64px;
        margin-top: 184px;
      }
      .position-title-desc {
        font-size: 56px;
        line-height: 66px;
        text-align: center;
      }
      .position-list-wrapper {
        margin-top: 105px;
        display: flex;
        justify-content: space-between;
        .position-list {
          width: 799px;
          height: 845px;
          background-color: rgba(2, 124, 250, 0.2);
          padding: 174px 0px;
          box-sizing: border-box;
          .list-icon {
            display: flex;
            justify-content: center;
            img {
              width: 166px;
              height: 166px;
            }
          }
          .list-bottom {
            margin-top: 45px;
            text-align: center;
            .list-title {
              font-size: 82px;
              line-height: 123px;
              margin-bottom: 65px;
            }
            .list-detail {
              font-size: 46px;
              line-height: 72px;
              cursor: pointer;
            }
          }
        }
      }
    }
    .development-wrapper {
      .new-development-box {
        display: flex;
        border-radius: 0px;
        overflow: hidden;
        flex-direction: column-reverse;
        background: #ffffff;
        .box-right {
          width: 100%;
          height: 1180px;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          background-size: 100% 100%;
          background-image: url("../assets/image/iphone/development_right_bg.png");
        }
      }
      .development-list-wrapper {
        padding-bottom: 207px;
        background: #f7f8fa;
        .development-list {
          padding-top: 223px;
          .development-list-content {
            width: 100%;
            padding: 0px 148px 0px;
            height: auto;
            background: #f7f8fa;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            flex-direction: column;
            .development-list-content-title {
              width: 530px;
              font-size: 51px;
              line-height: 82px;
              padding-bottom: 41px;
              position: relative;
              &:before {
                content: "";
                display: block;
                position: absolute;
                bottom: 0px;
                height: 1px;
                width: 100%;
                background: linear-gradient(
                  to right,
                  #4782f7 0%,
                  #4782f7 20%,
                  #e4e7eb 20%,
                  #e4e7eb 100%
                );
              }
            }
            .development-list-content-desc {
              font-size: 46px;
              line-height: 72px;
              margin-top: 84px;
              letter-spacing: -0.6px;
            }
          }
        }
      }
    }
    .nav-bar-wrapper {
      display: none;
    }
    .pc {
      display: none;
    }
    .h5 {
      display: block;
    }
  }
}
</style>
