<template>
  <div class="small-businesses">
    <div class="top-banner">
      <img
        class="pc"
        :src="require('../assets/image/small_businesses_top_banner.webp')"
        @load="imgLoad"
        alt=""
      />
      <img
        class="h5"
        :src="require('../assets/image/iphone/small_businesses_top_banner.png')"
        alt=""
      />
      <div class="base-container">
        <div class="banner-title">小微业务</div>
      </div>
    </div>
    <div class="nav-bar-wrapper">
      <div class="nav-bar" id="nav-bar-id">
        <div class="base-container">
          <div class="nav-bar-list-wrapper">
            <div
              :class="`nav-bar-list ${
                activeKey === item.key ? 'nav-bar-list-active' : ''
              }`"
              v-for="item in navBarList"
              :key="item.key"
              @click="navBarClick(item.key)"
            >
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="base-container small-businesses-container">
      <div class="base-title" id="business-development">业务发展</div>
      <div class="base-title-desc">
        孚厘深耕小微业务多年，已在小微业务领域取得优秀成绩
      </div>
      <div class="business-model-wrapper">
        <div class="business-model-list">
          <div class="business-model-list-item">
            <div class="item-left">
              <img
                :src="require('../assets/image/business_model_list1.png')"
                alt=""
              />
            </div>
            <div class="item-right">
              <div class="item-right-title">数字化金融体系建设</div>
              <div class="item-right-desc">
                帮助金融机构建设，数字化金融体系
              </div>
            </div>
          </div>
          <div class="business-model-list-item">
            <div class="item-left">
              <img
                :src="require('../assets/image/business_model_list2.png')"
                alt=""
              />
            </div>
            <div class="item-right">
              <div class="item-right-title">线上线下风控支撑</div>
              <div class="item-right-desc">
                基于专家经验结合算法、 模型等智能工具的风控手段
              </div>
            </div>
          </div>
        </div>
        <div class="business-model-list">
          <div class="business-model-list-item">
            <div class="item-left">
              <img
                :src="require('../assets/image/business_model_list3.png')"
                alt=""
              />
            </div>
            <div class="item-right">
              <div class="item-right-title">数字化业务运营</div>
              <div class="item-right-desc">
                专家团队长期全流程辅导提升业务能力
              </div>
            </div>
          </div>
          <div class="business-model-list-item">
            <div class="item-left">
              <img
                :src="require('../assets/image/business_model_list4.png')"
                alt=""
              />
            </div>
            <div class="item-right">
              <div class="item-right-title">高效能系统支撑</div>
              <div class="item-right-desc">
                微服务架构系统支持业务高并发高可用运行
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="company-distribution-wrapper">
        <div class="company-distribution-left">
          <div class="left-item">
            <div class="left-title">覆盖全国</div>
            <div class="left-number">10</div>
            <div class="left-desc">覆盖全国10个重点省市</div>
          </div>
          <div class="line"></div>
          <div class="left-item">
            <div class="left-title">分支机构</div>
            <div class="left-number">12</div>
            <div class="left-desc">共设置12家分支机构</div>
          </div>
        </div>
        <div class="company-distribution-right">
          <img :src="require('../assets/image/icon_map.png')" alt="" />
        </div>
      </div>
      <div class="source-wrapper">
        <div class="base-title" id="source">纯正的台州基因</div>
        <div class="base-title-desc">
          <div>核心团队来自小微特色银行，</div>
          <div>经历业务发展最快速10年开创出升级版“台州模式”</div>
        </div>
        <div class="source-list">
          <div class="source-list-left">
            <img :src="require('../assets/image/source_list1.png')" alt="" />
          </div>
          <div class="source-list-right">
            <div class="source-list-right-title">台州基因，源于银行</div>
            <div class="source-list-right-desc">
              核心团队来自小微特色银行高管+骨干信贷专家
            </div>
          </div>
        </div>
        <div class="source-list">
          <div class="source-list-left">
            <img :src="require('../assets/image/source_list2.png')" alt="" />
          </div>
          <div class="source-list-right">
            <div class="source-list-right-title">
              <div>开创升级版“台州模式”</div>
              <div>历经实践检验</div>
            </div>
            <div class="source-list-right-desc">
              基于30年台州小微模式的成功经验，开创小微金融+科技的完美组合，帮小微企业活得更好
            </div>
          </div>
        </div>
      </div>
      <div class="result-wrapper">
        <div class="base-title" id="result">风控显著</div>
        <div class="base-title-desc">
          <div>历时多年实践出一套线上线下相结合的人机模式，</div>
          <div>形成了历经实践检验的风控体系</div>
        </div>
        <div class="result-list-wrapper">
          <div class="result-list result-list1">
            <div class="result-list-mask">
              <div class="mask-title">专家风控</div>
              <div class="mask-desc">守住业务的底线</div>
            </div>
          </div>
          <div class="result-list result-list2">
            <div class="result-list-mask">
              <div class="mask-title">科技引领</div>
              <div class="mask-desc">自研湛泸系统让风控如虎添翼</div>
            </div>
          </div>
          <div class="result-list result-list3">
            <div class="result-list-mask">
              <div class="mask-title">完善模式</div>
              <div class="mask-desc">8个完整的周期检验</div>
            </div>
          </div>
          <div class="result-list result-list4">
            <div class="result-list-mask">
              <div class="mask-title">智能辅助</div>
              <div class="mask-desc">智能手段助力全实时风险辅助</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SmallBusinesses",
  data() {
    return {
      activeKey: "business-development",
      navBarList: [
        {
          title: "业务发展",
          key: "business-development",
        },
        {
          title: "台州基因",
          key: "source",
        },
        {
          title: "风控显著",
          key: "result",
        },
      ],
      domScrollTop: [],
      navBarScrollTop: 0,
      navBarHeight: 0,
    };
  },
  mounted() {
    // 获取当前锚点所有的dom
    const boxListDom = this.navBarList.map((item) =>
      document.getElementById(item.key)
    );
    this.navBarHeight = document.getElementById("nav-bar-id").offsetHeight;
    // 获取当前锚点所有的scrollTop
    const boxListTop = boxListDom.map((item) =>
      Math.floor(
        item.getBoundingClientRect().top +
          this.getScrollTop() -
          this.navBarHeight
      )
    );
    this.domScrollTop = boxListTop;
    window.addEventListener("scroll", this.scrollChange);
  },
  methods: {
    imgLoad() {
      // 获取小的navbar具体顶部的距离
      this.navBarScrollTop =
        document.getElementById("nav-bar-id").getBoundingClientRect().top +
        this.getScrollTop();
    },
    scrollChange() {
      let scrollTop = this.getScrollTop();
      // 根据滚动高度大导航栏消失
      if (scrollTop > 80) {
        let topNavBarClass = document.getElementById("top-nav-bar").className;
        if (topNavBarClass.indexOf("hidden-nav-bar") === -1) {
          document.getElementById("top-nav-bar").className += " hidden-nav-bar";
        }
      } else {
        document.getElementById("top-nav-bar").className = "nav-bar";
      }
      if (scrollTop > this.navBarScrollTop) {
        const currentNavBarClass =
          document.getElementById("nav-bar-id").className;
        if (currentNavBarClass.indexOf("fixed-nav-bar") === -1) {
          document.getElementById("nav-bar-id").className += " fixed-nav-bar";
        }
      } else {
        document.getElementById("nav-bar-id").className = "nav-bar";
      }
      if (scrollTop > 0 && scrollTop < this.domScrollTop[1]) {
        this.activeKey = this.navBarList[0].key;
      } else if (
        scrollTop >= this.domScrollTop[1] &&
        scrollTop < this.domScrollTop[2]
      ) {
        this.activeKey = this.navBarList[1].key;
      } else if (scrollTop >= this.domScrollTop[2]) {
        this.activeKey = this.navBarList[2].key;
      }
    },
    getScrollTop() {
      let scrollTop =
        document.documentElement.scrollTop ||
        window.pageYOffset ||
        document.body.scrollTop;
      return scrollTop;
    },
    navBarClick(key) {
      this.activeKey = key;
      let scrollTop =
        this.getScrollTop() +
        document.getElementById(key).getBoundingClientRect().top;
      document.documentElement.scrollTop = scrollTop - this.navBarHeight;
      document.body.scrollTop = scrollTop - this.navBarHeight;
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollChange);
  },
};
</script>

<style scoped lang="scss">
.small-businesses {
  .top-banner {
    position: relative;
    width: 100%;
    font-size: 0px;
    img {
      width: 100%;
      height: auto;
    }
    .base-container {
      position: absolute;
      left: 50%;
      top: 0px;
      transform: translateX(-50%);
      height: 100%;
    }
    .banner-title {
      font-size: 48px;
      font-weight: bold;
      color: #ffffff;
      line-height: 52px;
      position: absolute;
      top: 50%;
      left: 0px;
      transform: translateY(-50%);
    }
  }
  .nav-bar-wrapper {
    height: 61px;
    width: 100%;
    margin-bottom: 50px;
  }
  .nav-bar {
    height: 61px;
    border-bottom: 1px solid #e7e7e7;
    background: white;
    .base-container {
      height: 100%;
      display: flex;
    }
    .nav-bar-list-wrapper {
      display: flex;

      .nav-bar-list {
        width: 144px;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .nav-bar-list-active {
        font-weight: bold;
        color: #333333;
        position: relative;
        border-bottom: 2px solid #4782f7;
      }
    }
  }
  .fixed-nav-bar {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 1000;
  }
  .small-businesses-container {
    .base-title {
      font-size: 36px;
      font-weight: bold;
      color: #333333;
      line-height: 50px;
      text-align: center;
      margin-top: 80px;
    }
    .base-title-desc {
      font-size: 16px;
      font-weight: 400;
      color: #666666;
      line-height: 26px;
      text-align: center;
      margin-top: 10px;
    }
    .business-model-wrapper {
      margin-top: 48px;
      margin-bottom: 80px;
      .business-model-list {
        margin-bottom: 28px;
        display: flex;
        justify-content: space-between;
        .business-model-list-item {
          width: 586px;
          background: #f7f8fa;
          display: flex;
          padding: 62px 62px;
          box-sizing: border-box;
          border-radius: 6px;
          .item-left {
            height: 105px;
            width: 105px;
            margin-right: 34px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .item-right {
            flex: 1;
            display: flex;
            justify-content: center;
            flex-direction: column;
            .item-right-title {
              font-size: 28px;
              font-weight: bold;
              color: #333333;
              line-height: 33px;
              margin-bottom: 10px;
            }
            .item-right-desc {
              font-size: 16px;
              font-weight: 400;
              color: #666666;
              line-height: 26px;
            }
          }
        }
      }
    }
    .company-distribution-wrapper {
      display: flex;
      .company-distribution-left {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .left-item {
          .left-title {
            font-size: 18px;
            font-weight: bold;
            color: #666666;
            line-height: 24px;
            margin-bottom: 18px;
          }
          .left-number {
            font-size: 64px;
            font-weight: bold;
            color: #0070ff;
            line-height: 52px;
            margin-bottom: 18px;
          }
          .left-desc {
            font-size: 14px;
            color: #666666;
            line-height: 24px;
          }
        }
        .line {
          width: 109px;
          height: 10px;
          background: #4782f7;
          margin: 38px 0px;
        }
      }
      .company-distribution-right {
        width: 676px;
        height: 558px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .source-wrapper {
      .source-list {
        display: flex;
        margin-top: 48px;
        margin-bottom: 36px;
        &:last-child {
          margin-bottom: 0px;
          margin-top: 0px;
        }

        .source-list-left {
          width: 404px;
          height: 285px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .source-list-right {
          flex: 1;
          padding: 46px 69px;
          box-shadow: 0px 0px 20px 0px rgba(182, 182, 182, 0.25);
          .source-list-right-title {
            font-size: 28px;
            font-weight: bold;
            color: #333333;
            line-height: 35px;
            position: relative;
            padding-bottom: 20px;
            &:before {
              content: "";
              display: block;
              width: 342px;
              height: 1px;
              background: linear-gradient(
                to right,
                #4782f7 0%,
                #4782f7 20%,
                #e4e7eb 20%,
                #e4e7eb 100%
              );
              position: absolute;
              left: 0px;
              bottom: 0px;
            }
          }
          .source-list-right-desc {
            font-size: 14px;
            font-weight: 400;
            color: #666666;
            line-height: 24px;
            margin-top: 48px;
          }
        }
      }
    }
    .result-list-wrapper {
      margin-top: 38px;
      margin-bottom: 80px;
      display: flex;
      justify-content: space-between;
      .result-list {
        width: 285px;
        height: 499px;
        border-radius: 20px;
        background: #4782f7;
        position: relative;
        overflow: hidden;
        background-size: 100%;
        cursor: pointer;
        .result-list-mask {
          transition: all 0.2s linear;
          position: absolute;
          top: 384px;
          left: 0px;
          height: 100%;
          width: 100%;
          background: linear-gradient(
            90deg,
            rgba(0, 0, 0, 0.52) 0%,
            rgba(0, 0, 0, 0) 100%
          );
          padding: 32px 20px;
          border-radius: 0px 0px 20px 20px;
          box-sizing: border-box;
          .mask-title {
            font-size: 20px;
            font-weight: bold;
            color: #ffffff;
            line-height: 24px;
          }
          .mask-desc {
            margin-top: 2px;
            font-size: 14px;
            font-weight: bold;
            color: #ffffff;
            line-height: 24px;
          }
        }
        &:hover {
          .result-list-mask {
            top: 0px;
            left: 0px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: rgba(71, 130, 247, 0.8);
            transition: all 0.2s linear;
          }
        }
      }
      .result-list1 {
        background-image: url("../assets/image/result_list_bg1.png");
      }
      .result-list2 {
        background-image: url("../assets/image/result_list_bg2.png");
      }
      .result-list3 {
        background-image: url("../assets/image/result_list_bg3.png");
      }
      .result-list4 {
        background-image: url("../assets/image/result_list_bg4.png");
      }
    }
  }
  .h5 {
    display: none;
  }
}
@media screen and (max-width: 992px) {
  .small-businesses {
    .top-banner {
      position: relative;
      width: 100%;
      img {
        width: 100%;
        height: auto;
      }
      .banner-title {
        font-size: 123px;
        font-weight: bold;
        color: #ffffff;
        line-height: 133px;
        margin-left: 115px;
      }
    }
    .nav-bar-wrapper {
      display: none;
    }
    .small-businesses-container {
      .base-title {
        font-size: 82px;
        line-height: 123px;
        margin-top: 154px;
      }
      .base-title-desc {
        font-size: 56px;
        font-weight: 400;
        color: #666666;
        line-height: 70px;
        margin-top: 64px;
      }
      .business-model-wrapper {
        margin-top: 105px;
        margin-bottom: 164px;
        .business-model-list {
          margin-bottom: 72px;
          .business-model-list-item {
            width: 847px;
            background: #f7f8fa;
            border-radius: 5px;
            padding: 159px 51px;
            flex-direction: column;
            align-items: center;
            .item-left {
              margin-right: 0px;
              width: 269px;
              height: 269px;
            }
            .item-right {
              margin-top: 46px;
              .item-right-title {
                font-size: 72px;
                line-height: 84px;
                text-align: center;
                margin-bottom: 26px;
              }
              .item-right-desc {
                font-size: 46px;
                line-height: 82px;
                text-align: center;
              }
            }
          }
        }
      }
      .company-distribution-wrapper {
        .company-distribution-left {
          .left-item {
            .left-title {
              font-size: 46px;
              line-height: 61px;
              margin-bottom: 46px;
            }
            .left-number {
              font-size: 164px;
              line-height: 133px;
              margin-bottom: 46px;
            }
            .left-desc {
              font-size: 36px;
              line-height: 61px;
            }
          }
          .line {
            height: 18px;
            margin: 100px 0px;
          }
        }
        .company-distribution-right {
          width: 1280px;
          height: 1057px;
        }
      }
      .source-wrapper {
        .source-list {
          flex-direction: column;
          margin-top: 105px;
          margin-bottom: 92px;
          .source-list-left {
            width: 100%;
            height: 730px;
          }
          .source-list-right {
            padding: 90px;
            height: 730px;
            .source-list-right-title {
              font-size: 72px;
              line-height: 84px;
              padding-bottom: 41px;
              &:before {
                width: 876px;
              }
            }
            .source-list-right-desc {
              font-size: 56px;
              line-height: 66px;
              margin-top: 123px;
            }
          }
        }
      }
      .result-list-wrapper {
        display: block;
        margin-bottom: 154px;
        margin-top: 105px;
        .result-list {
          width: 100%;
          height: 463px;
          border-radius: 10px;
          margin-bottom: 51px;
          &:last-child {
            margin-bottom: 0px;
          }
          .result-list-mask {
            top: 0px;
            left: 0px;
            background: linear-gradient(
              90deg,
              rgba(71, 130, 247, 0.92) 0%,
              rgba(124, 184, 255, 0) 100%
            );
            border-radius: 10px;
            padding: 102px 0px 0px 102px;
            .mask-title {
              font-size: 72px;
              line-height: 84px;
            }
            .mask-desc {
              font-size: 46px;
              line-height: 72px;
              font-weight: 400;
              margin-top: 20px;
            }
          }
          &:hover {
            .result-list-mask {
              top: 0px;
              left: 0px;
              display: block;
              background: linear-gradient(
                90deg,
                rgba(71, 130, 247, 0.92) 0%,
                rgba(124, 184, 255, 0) 100%
              );
            }
          }
        }
        .result-list1 {
          background-image: url("../assets/image/iphone/result_list_bg1.png");
        }
        .result-list2 {
          background-image: url("../assets/image/iphone/result_list_bg2.png");
        }
        .result-list3 {
          background-image: url("../assets/image/iphone/result_list_bg3.png");
        }
        .result-list4 {
          background-image: url("../assets/image/iphone/result_list_bg4.png");
        }
      }
    }
    .h5 {
      display: block;
    }
    .pc {
      display: none;
    }
  }
}
</style>
